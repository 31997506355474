import React, { useState } from "react";
import "./Navbar.css"

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  return (
    <nav className="nav">
      <div>
        <a href="#">
          <img className="nav__brand" src={require("../../assets/logo.png")} alt="" />
        </a>
      </div>

      <div className="nav_item_div">
        <ul className={active}>
          <li className="nav__item">
            <a href="#aboutus" className="nav__link">
              About Us
            </a>
          </li>
          <li className="nav__item">
            <a href="#projects" className="nav__link">
              Projects
            </a>
          </li>
          <li className="nav__item">
            <a href="#contact" className="nav__link">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div className="nav__hello">

      </div>


      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;
